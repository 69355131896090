// Webpack Imports
import 'bootstrap';

AOS.init(
	{
		// Global settings:
		disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
		startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
		initClassName: 'aos-init', // class applied after initialization
		animatedClassName: 'aos-animate', // class applied on animation
		useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
		disableMutationObserver: false, // disables automatic mutations' detections (advanced)
		debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
		throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
	
		// // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
		offset: 120, // offset (in px) from the original trigger point
		delay: 0, // values from 0 to 3000, with step 50ms
		duration: 1000, // values from 0 to 3000, with step 50ms
		easing: 'ease', // default easing for AOS animations
		once: false, // whether animation should happen only once - while scrolling down
		mirror: false, // whether elements should animate out while scrolling past them
		anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
	}
);

( function ( $ ) {
	'use strict';

	$('#navbar').on('show.bs.collapse', function(){
		$('body').addClass('overflow-hidden');
	}).on('hide.bs.collapse', function(){
		$('body').removeClass('overflow-hidden');
	});
	// Focus Search if Searchform is empty
	$( '.search-form' ).on( 'submit', function ( e ) {
		var search = $( '#s' );
		if ( search.val().length < 1 ) {
			e.preventDefault();
			search.focus();
		}
	} );

	volverArriba();

    function volverArriba(){
        $('.volver_arriba').on('click', function(e){
			$('html, body').animate({ scrollTop: '0' }, 250); 
        });

        $(window).on('scroll', function(){
            if($(this).scrollTop() > 100){ 
				$('.header__scroll_down').fadeOut();
                $('.volver_arriba').slideDown(600);
            } else { 
				$('.header__scroll_down').fadeIn();
                $('.volver_arriba').slideUp(600); 
            }
        });
    }

	const animationLogoConfig = {
		renderer: 'svg',
		loop: true,
		autoplay: true,
		path: '/wp-content/themes/ideable-theme/assets/js/animacion_logo_inicio.json'
	};

	let animationLogoConfigInicio 		= {...animationLogoConfig};
	animationLogoConfigInicio.container = document.getElementById('somos_ideable__galeria__logo_ideable');
	let animationLogoInicio 			= bodymovin.loadAnimation(animationLogoConfigInicio);

	let animationLogoConfigEmpleo 		= {...animationLogoConfig};
	animationLogoConfigEmpleo.container = document.getElementById('empleo__galeria');
	animationLogoConfigEmpleo.rendererSettings = {
		className: 'empleo__galeria__logo_ideable',
	};
	let animationLogoEmpleo 				= bodymovin.loadAnimation(animationLogoConfigEmpleo);
	


	let wpBlockquotes = document.getElementsByClassName('wp-block-quote');
	for( let b = 0; b < wpBlockquotes.length; b++ )	{	
		let animationBlockquote = bodymovin.loadAnimation({
			container: wpBlockquotes[b],
			renderer: 'svg',
			loop: true,
			autoplay: true,
			path: '/wp-content/themes/ideable-theme/assets/js/animacion_logo_destacados.json',
			rendererSettings: {
				className: 'idbl-lottie-animation',
			}
		});
	};

	let animationSomosExpertosConfig = {
		renderer: 'svg',
		loop: false,
		autoplay: false,
	}
	const animationPaths = [
		'/wp-content/themes/ideable-theme/assets/js/animacion_icono_industria_40.json',
		'/wp-content/themes/ideable-theme/assets/js/animacion_icono_ehealth.json',
		'/wp-content/themes/ideable-theme/assets/js/animacion_icono_soluciones_a_medida.json',
		'/wp-content/themes/ideable-theme/assets/js/animacion_icono_innovacion.json'
	];
	for( let a = 1; a <= 4; a++ ){
		let animationConfig 		= {...animationSomosExpertosConfig}
		animationConfig.container 	= document.getElementById('somos_expertos__campo__icono--' + a);
		animationConfig.path 		= animationPaths[a-1];
		let animationSomosExpertos 	= bodymovin.loadAnimation(animationConfig);

		$('#somos_expertos__campo__icono--' + a).parent().on('mouseenter', function(){
			animationSomosExpertos.goToAndPlay(0);
		})
	}
	
}( jQuery ) );
